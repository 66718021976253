 jQuery(document).ready(function() {
	jQuery('.bxslider').bxSlider({
		pager: (jQuery('.bxslider').children().length < 2) ? false : true,
	    auto: (jQuery('.bxslider').children().length < 2) ? false : true,
		touchEnabled:(jQuery('.bxslider').children().length < 2) ? false : true,
		adaptiveHeight:true,
        controls: false
    });


    jQuery(".mobile-icon a").on("click touchstart", function (event) {
        event.preventDefault();
		event.stopPropagation();
		jQuery("body").toggleClass("open-menu");
    });

    jQuery(document).on('click', function () {
        jQuery('body').removeClass('open-menu');
    });

	jQuery(".site-header .header-right").on("click touchstart", function (event) {
		event.stopPropagation();
    });


	jQuery(".touch .main-navigation ul li > a").on("click touchstart",function(event){

	   if(!jQuery(this).parent().hasClass("open") && jQuery(this).parent().has("ul").length > 0)
	   {
		event.preventDefault();
		event.stopPropagation();
		jQuery(this).parent().addClass("open").siblings().removeClass("open");
	   }
	  });


	footerH = jQuery('.site-footer').outerHeight();
    //jQuery('.site-content').css({'margin-bottom': footerH});

	equalheight('.eq-height');
    equalheight('.product-image');

});


jQuery(window).load(function(){
    footerH = jQuery('.site-footer').outerHeight();
    //jQuery('.site-content').css({'margin-bottom': footerH});

	equalheight('.eq-height');
	equalheight('.product-image');
});

jQuery(window).resize(function(){
    footerH = jQuery('.site-footer').outerHeight();
    //jQuery('.site-content').css({'margin-bottom': footerH});

	equalheight('.eq-height');
	equalheight('.product-image');

});


equalheight = function (container) {
    if (jQuery(window).width() > 767) {
        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            jQueryel,
            topPosition = 0;
        jQuery(container).each(function () {
            jQueryel = jQuery(this);
            jQuery(jQueryel).innerHeight('auto');
            topPostion = jQueryel.offset().top;
            if (currentRowStart != topPostion) {
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                    rowDivs[currentDiv].innerHeight(currentTallest);
                }
                rowDivs.length = 0; // empty the array
                currentRowStart = topPostion;
                currentTallest = jQueryel.innerHeight();
                rowDivs.push(jQueryel);
            } else {
                rowDivs.push(jQueryel);
                currentTallest = (currentTallest < jQueryel.innerHeight()) ? (jQueryel.innerHeight()) : (currentTallest);
            }
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].innerHeight(currentTallest);
            }
        });
    } else {
        jQuery(container).height('auto');
    }
};
